<template>
  <div class="header-menu">
    <header :class="isAbout ? 'about' : ''">
      <div class="ui horizontal list">
        <div class="item ui left floated header">
          <a href="/"><img class="logo" src="@/assets/logo.svg" alt="Tayocash"></a>
          <a href="/"><img class="logo-white" src="@/assets/logo-white.svg" alt="Tayocash"></a>
        </div>
        <div class="right">
          <div v-for="links in listitems" class="item" :key="links.linkto" @click="scrollToTop">
            <router-link :active="isActive" :to="links.linkto" replace>{{ links.linkname}}
            </router-link>
          </div>
        </div>
      </div>
      <div class="mobilemenu">
        <div class="item ui left floated header">
          <a href="/"><img class="logo" src="@/assets/logo.svg" alt="Tayocash"></a>
          <a href="/"><img class="logo-white" src="@/assets/logo-white.svg" alt="Tayocash"></a>
        </div>
        <nav role='navigation'>
          <div id="menuToggle">
            <input id="checkbox" type="checkbox" />
            <span></span>
            <span></span>
            <span></span>
            <ul id="menu">
              <a href="/"><img class="menulogo" src="@/assets/logo.svg" alt="Tayocash"></a>
              <div class="links-menu">
                <div v-for="links in listitems" class="item" :key="links.linkto" @click="close">
                  <router-link :active="isActive" :to="links.linkto" replace>{{ links.linkname}}
                  </router-link>
                </div>
              </div>
            </ul>
          </div>
        </nav>
      </div>
    </header>

  </div>
</template>
<script>
import $ from 'jquery'

export default {
  name: 'headermenu',
  data () {
    return {
      isActive: false,
      isAbout: false,
      // links: '',
      listitems: [
        { linkto: '/instapay', linkname: 'What’s New?'},
        { linkto: '/about', linkname: 'About'},
        { linkto: '/member', linkname: 'Be a Member'},
        { linkto: '/partner', linkname: 'Be a Partner' },
        // { linkto: '/promo', linkname: 'Promos' },
        { linkto: '/helpcenter', linkname: 'Help Center' }
        // { linkto: '/faq/membership', linkname: 'Member' }
      ]
      // web: true
    }
  },
  components: {
  },
  methods: {
    stickMenu () {
      $(window).scroll(function () {
        if ($(this).scrollTop() > 70) {
          $('header').addClass('onscroll')
        } else {
          $('header').removeClass('onscroll')
        }
      })
    },

    scrollToTop () {
      window.scrollTo(0, 0)
    },

    close () {
      window.scrollTo(0, 0)
      $('input:checkbox').prop('checked', false);
    }
  },
  mounted () {

    $( ".ui.horizontal.list .item:last-child" ).append( "<label class='ui red empty circular label'></label>" );

    $( "#menuToggle .item:last-child" ).append( "<label class='ui red empty circular label'></label>" );

    $('.item:first-child').addClass('new');
    this.stickMenu()
    if (this.$route.path === '/about') {
      this.isAbout = true
      $('img.logo').css('display', 'none')
      $('img.logo-white').css('display', 'block')
    } else {
      $('img.logo').css('display', 'block')
      $('img.logo-white').css('display', 'none')
      this.isAbout = false
    }

    // if ($(window).width() < 769) {
    //   this.web = false
    // }
    // else {
    //   this.web = true
    // }
  }
}
</script>

<style lang="scss">

.specialItem {
  opacity: 0;
}
</style>
